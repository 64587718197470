import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() background = 'gradient-light';
  @Input() textColor = 'dark';
  @Input() cursor = '';
  @Input() padding = '20px';
  @Input() borderRadius = '10px';
  @Input() borderColor = 'light-gray';
  @Input() borderStyle = 'solid';
  @Input() borderWidth = '1px';
  @Input() class = '';
  @Input() pin: boolean = false;
}
